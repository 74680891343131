<template>
  <b-row>
    <b-col
      v-if="activeList"
      md="6"
    >
      <EntityCardHeader
        title="Общее"
        :is-true="false"
        :is-save="general"
        @openDetailModal="general = !general"
        @updateColumns="updateAll"
      />
      <!--   click   openGeneralDetailsModal-->
      <div
        class="mt-50 d-flex align-items-center"
        :class="general ? '' : 'justify-content-between'"
      >
        <div>УИД:</div>
        <span class="ml-50 font-weight-bold w-50 text-dark">{{ SetSkLegalDebt.GUID }}</span>
      </div>
      <div
        v-for="item in fields.general"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="general ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>

        <b-form-input
          v-model="SetSkLegalDebt.BASIS[item.unique_name]"
          :plaintext="general"
          :class="general ? 'ml-50 font-weight-bold w-50' : 'ml-1 w-50'"
        />
      </div>
    </b-col>

    <b-col
      v-if="activeList"
      md="6"
    >
      <!--      openConditionDetailsModal-->
      <EntityCardHeader
        title="Состояние"
        :is-true="false"
        :is-save="condition"
        @openDetailModal="condition = !condition"
        @updateColumns="updateState"
      />
      <div
        v-for="item in fields.condition"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="condition ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>
        <b-form-input
          v-model="SetSkLegalDebt.BASIS[item.unique_name]"
          :plaintext="condition"
          :class="condition ? 'ml-50 font-weight-bold w-25' : 'ml-1 w-auto'"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import constants from '@/modules/entity/constants/personalAccounts'
import {
  BRow,
  BCol, BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import EntityCardHeader from '../EntityCardHeader.vue'

export default {
  components: {
    BFormInput,
    // bootstrap
    BRow,
    BCol,
    // components
    EntityCardHeader,
  },
  props: {
    activeList: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields: {
        general: constants.general,
        condition: constants.condition,
      },
      items: {
        generalItems: constants.generalItems,
        conditionItems: constants.conditionItems,
      },
      general: true,
      condition: true,
    }
  },
  computed: {
    ...mapState('entity', ['SetSkLegalDebt']),
  },
  methods: {
    ...mapActions('entity', ['UPDATE_SK_JUDICAL_WORK_ID']),

    updateAll() {
      const {
        CURRENCY, CITY_NAME, TYPE_CREDIT, SUBJECT_AGREEMENT, DEBT_TYPE, DEBT_START_DATE, DEBT_END_DATE, DEBT_SUM, DEBT_PERIOD, INTEREST_RATE,
      } = this.SetSkLegalDebt.BASIS

      this.UPDATE_SK_JUDICAL_WORK_ID({
        id: this.SetSkLegalDebt.BASIS.id,
        data: {
          CURRENCY, CITY_NAME, TYPE_CREDIT, SUBJECT_AGREEMENT, DEBT_TYPE, DEBT_START_DATE, DEBT_END_DATE, DEBT_SUM, DEBT_PERIOD, INTEREST_RATE,
        },
      })
    },

    updateState() {
      this.UPDATE_SK_JUDICAL_WORK_ID({
        id: '0',
        data: {},
      })
    },

    // openGeneralDetailsModal() {
    //   this.$bvModal.show('personalAccountGeneralDetails')
    // },
    //
    // openConditionDetailsModal() {
    //   this.$bvModal.show('personalAccountConditionDetails')
    // },
  },
}
</script>
