<template>
  <div>
    <div>
      <div class="rounded  w-100  pb-1">
        <BButton
          v-for="(item, index) in sklegalExecutiveDocument.results"
          :key="index"
          class="border mr-1"
          variant="light"
          :class="{'activeList' : current === item.id}"
          @click="filteredDate(item)"
        >
          Работа от {{ item.EXECUTIVE_DATE }}
        </BButton>
      </div>
      <EntityCardHeader
        title="Общее"
        :is-true="!!(sklegalExecutiveWork.results && sklegalExecutiveWork.results.length)"
        :is-save="all"
        @openDetailModal="all = !all"
        @updateColumns="updateAll"
      />
      <div
        v-for="item in fields.all"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="all ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>
        <b-form-input
          v-if="item.unique_name !== 'document' && item.unique_name !== 'SSP_OSP_NAME'"
          v-model="sklegalExecutiveDocumentId.EXECUTIVE_WORK[item.unique_name]"
          :plaintext="all"
          :class="all ? 'ml-50 font-weight-bold w-25' : 'ml-1 w-auto'"
        />
        <!--if unique_name is equal to 'document'-->
        <p
          v-if="item.unique_name === 'document'"
          class="mb-0 text-info ml-1 cursor-pointer"
          @click="openDocModal"
        >
          <span v-if="sklegalExecutiveDocumentId && sklegalExecutiveDocumentId.id">Документы</span>
        </p>
        <p
          v-if="item.unique_name === 'SSP_OSP_NAME'"
          class="mb-0 text-dark ml-1 font-weight-bold"
        >
          {{ sklegalExecutiveDocumentId.EXECUTIVE_WORK[item.unique_name] }}
        </p>
      </div>
    </div>

    <div class="mt-5">
      <!--      :is-true="sklegalExecutiveDocumentId && sklegalExecutiveDocumentId.id"-->
      <EntityCardHeader
        title="Суммы"
        :is-save="sum"
        :is-true="false"
        @openDetailModal="sum = !sum"
        @updateColumns="updateSum"
      />
      <div
        v-for="item in fields.sum"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="sum ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>
        <b-form-input
          v-model="sklegalExecutiveDocumentId[item.unique_name]"
          :plaintext="sum"
          type="number"
          :class="sum ? 'ml-50 font-weight-bold w-25' : 'ml-1 w-auto'"
        />
      </div>
    </div>

    <!--//document modal-->
    <theDocModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
    <!--//document modal end-->
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import EntityCardHeader from '@/modules/entity/pages/components/EntityCardHeader.vue'
import constants from '@/modules/entity/constants/ssp'
import { mapActions, mapState } from 'vuex'
import theDocModal from '@components/modalDocDebts/theDocModal.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    theDocModal,
    BButton,
    BFormInput,
    EntityCardHeader,
  },
  props: {
    activeList: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields: {
        all: constants.all,
        sum: constants.sum,
      },
      all: true,
      sum: true,
      current: null,
      type: null,
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
    }
  },
  computed: {
    ...mapState('entity', ['sklegalExecutiveDocument', 'sklegalExecutiveDocumentId', 'sklegalExecutiveWork']),
    ...mapState('cases', ['debtDocument']),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },
  mounted() {
    this.FETCH_SKLEGAL_EXECUTIVE_DOCUMENT({ DOSSIER_ID: this.activeList })
      .then(() => {
        if (this.sklegalExecutiveDocument.results && this.sklegalExecutiveDocument.results.length > 0) {
          const firstResultId = this.sklegalExecutiveDocument.results[0].id
          this.filteredDate({ id: firstResultId })
        }
      })
  },
  methods: {
    ...mapActions('entity', ['FETCH_SKLEGAL_EXECUTIVE_DOCUMENT', 'FETCH_SKLEGAL_EXECUTIVE_DOCUMENT_ID', 'UPDATE_SK_LEGAL_EX_DOCUMENT', 'UPDATE_SKLEGALEXECUTIVEDOCUMENT', 'FETCH_SKLEGAL_EXECUTIVE_WORK']),
    ...mapActions('cases', ['CREATE_DEBT_DOCUMENT', 'UPDATE_DEBT_DOCUMENT', 'DELETE_DEBT_DOCUMENT', 'FETCH_DEBT_DOCUMENT']),

    updateAll() {
      const { SSP_OSP_ADDRESS } = this.sklegalExecutiveDocumentId.EXECUTIVE_WORK

      this.UPDATE_SKLEGALEXECUTIVEDOCUMENT({
        id: this.sklegalExecutiveDocumentId.EXECUTIVE_WORK.id,
        SSP_OSP_ADDRESS,
      }).then(() => {
        this.all = true
        this.$_okToast()
      }).catch(e => {
        this.$_errorToast(e)
      })
    },

    updateSum() {
      const { EXECUTIVE_SUM, REST_SUM, REST_SUM_LONG } = this.sklegalExecutiveDocumentId

      this.UPDATE_SK_LEGAL_EX_DOCUMENT({
        id: this.sklegalExecutiveDocumentId.id,
        data: {
          EXECUTIVE_SUM, REST_SUM, REST_SUM_LONG,
        },
      }).then(() => {
        this.sum = true
        this.$_okToast()
      }).catch(e => {
        this.$_errorToast(e)
      })
    },

    filteredDate(item) {
      this.current = item.id
      this.FETCH_SKLEGAL_EXECUTIVE_DOCUMENT_ID({ id: item.id })
      this.FETCH_SKLEGAL_EXECUTIVE_WORK({ EXECUTIVE_DOC_ID: item.id })
      this.all = true
    },

    // ******************** document **************************//
    openDocModal() {
      if (this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.CONTENT_TYPE) {
        this.$bvModal.show('modalDoc')
        this.docRefresh()
      }
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }

      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.CONTENT_TYPE)
      formData.append('object_id', this.sklegalExecutiveDocumentId.EXECUTIVE_WORK.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.hideModal()
          this.$bvModal.hide('addEditDoc')
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.CONTENT_TYPE)
      formData.append('object_id', this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.tags = []
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.CONTENT_TYPE,
        object_id: this.sklegalExecutiveDocumentId.EXECUTIVE_WORK?.id,
        ...data,
      })
    },
    // ******************** document end **************************//
  },
}
</script>
<style>
.activeList {
  background: #2639BB !important;
  color: #fff !important;
}

.activeList svg:nth-child(1) {
  color: #fff;
}
</style>
