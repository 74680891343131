<template>
  <b-row>
    <div class="rounded  w-100 px-1 pb-1">
      <BButton
        v-for="(item, index) in skJudicalWork.results"
        :key="index"
        class="border mr-1"
        variant="light"
        :class="{'activeList' : current === item.id}"
        @click="filteredDate(item)"
      >
        Работа от {{ item.JUD_DATE_SENT }}
      </BButton>
    </div>

    <b-col md="6">
      <EntityCardHeader
        title="Судебная работа"
        :is-true="!!(skJudicalWorkId && skJudicalWorkId.id)"
        :is-save="forensicWork"
        @openDetailModal="forensicWork = !forensicWork"
        @updateColumns="updateForensicWork"
      />
      <div
        v-for="item in fields.forensicWork"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="forensicWork ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>

        <b-form-input
          v-if="item.unique_name !== 'document'
            && item.unique_name !== 'VICARIOUS_LIABILITY'
            && item.unique_name !== 'has_adjudgement'
            && item.unique_name !== 'JUD_TYPE'
            && item.unique_name !== 'JUD_DATE_SENT'
            && item.unique_name !== 'JUD_GP'
            && item.unique_name !== 'adjudgement_sum'
            && item.unique_name !== 'AMOUNT_CLAIMS'
          "
          v-model="skJudicalWorkId[item.unique_name]"
          :plaintext="item.unique_name === 'adjudgement_date' || item.unique_name === 'adjudgement_sum' ? true: forensicWork"
          :class="forensicWork ? 'ml-50 font-weight-bold w-25' : 'ml-1 w-auto text-right mr-1'"
        />

        <p
          v-if="
            item.unique_name === 'JUD_DATE_SENT' &&
              item.unique_name === 'JUD_GP' &&
              item.unique_name === 'adjudgement_sum' &&
              item.unique_name === 'AMOUNT_CLAIMS'
          "
          class="mb-0 text-dark ml-1 mr-1 font-weight-bold"
        >
          {{ skJudicalWorkId[item.unique_name] }}
        </p>
        <!--if unique_name is equal to 'document'-->
        <p
          v-if="item.unique_name === 'document' && skJudicalWorkId.id"
          class="mb-0 text-info ml-1 mr-1 cursor-pointer"
          @click="openDocModal"
        >
          Документы
        </p>

        <p
          v-if="item.unique_name === 'has_adjudgement'"
          class="mb-0 text-dark ml-1 mr-1 font-weight-bold"
        >
          {{ skJudicalWorkId.has_adjudgement ? 'Да' : 'Нет' }}
        </p>

        <p
          v-if="item.unique_name === 'VICARIOUS_LIABILITY'"
          class="mb-0 text-dark ml-1 mr-1 font-weight-bold"
        >
          {{ skJudicalWorkId.VICARIOUS_LIABILITY ? 'Да' : 'Нет' }}
        </p>

        <p
          v-if="item.unique_name === 'JUD_TYPE'"
          class="mb-0 text-dark ml-50 font-weight-bold w-50 mr-1"
        >
          <v-select
            v-if="!forensicWork"
            v-model="skJudicalWorkId.JUD_TYPE"
            label="title"
            class="w-100"
            :reduce="p => p.value"
            :options="JUD_TYPE_LIST"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <span v-else>{{ getJudType }}</span>
        </p>
      </div>
    </b-col>

    <b-col md="6">
      <EntityCardHeader
        title="Суд"
        :is-true="false"
      />
      <!--      :is-save="sud"-->
      <!--      @openDetailModal="sud = !sud"-->
      <div
        class="mt-1 d-flex align-items-center"
        :class="sud ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          Акт от {{ skLegalAdjudgement.DOCUMENT_DATE }}:
        </p>
        <p
          v-if="skLegalAdjudgement.id"
          class="mb-0 text-info ml-1 cursor-pointer"
          @click="openActModal"
        >
          {{ skLegalAdjudgement.DOCUMENT_DATE }}
        </p>
      </div>
    </b-col>

    <!--//document modal-->
    <theDocModal
      ref="documents"
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
    <!--//document modal end-->

    <!--    /////judicalActModal-->
    <judicalActModal
      @refresh="refresh"
    />
    <!--    /////judicalActModal end-->
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import EntityCardHeader from '@/modules/entity/pages/components/EntityCardHeader.vue'
import constants from '@/modules/entity/constants/forensicWork'
import { mapActions, mapState } from 'vuex'
import theDocModal from '@components/modalDocDebts/theDocModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import judicalActModal from './judicalActModal.vue'

export default {
  components: {
    vSelect,
    theDocModal,
    BButton,
    // bootstrap
    BRow,
    BCol,
    BFormInput,
    // components
    EntityCardHeader,
    judicalActModal,
  },
  props: {
    activeList: {
      type: Number,
      default: () => (0),
    },
  },
  data() {
    return {
      type: 'judical',
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      fields: {
        forensicWork: constants.forensicWork,
      },
      items: {
        forensicWorkItems: constants.forensicWorkItems,
      },
      forensicWork: true,
      sud: true,
      current: null,
      selected: true,
    }
  },
  computed: {
    ...mapState('entity', ['skJudicalWork', 'skJudicalWorkId', 'skLegalAdjudgement']),
    ...mapState('cases', ['debtDocument', 'JUD_TYPE_LIST']),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    getJudType() {
      let text = ''
      // eslint-disable-next-line default-case
      switch (this.skJudicalWorkId.JUD_TYPE) {
        case 'perviy_isk':
          text = 'Первый иск'
          break
        case 'vtoroy_isk':
          text = 'Второй иск'
          break
        case 'perviy_sudebnyy_prikaz':
          text = 'Первый судебный приказ'
          break
        case 'vtoroy_sudebnyy_prikaz':
          text = 'Второй судебный приказ'
          break
        case 'dlyashiesya_trebovaniya':
          text = 'Длящиеся требования'
          break
        case 'isk_na_YU':
          text = 'Иск на ЮУ'
          break
        case 'index1':
          text = 'Индексация 1'
          break
        case 'index2':
          text = 'Индексация 2'
          break
      }
      return text
    },
  },
  mounted() {
    this.FETCH_SK_JUDICAL_WORK({ DOSSIER_ID: this.activeList })
      .then(() => {
        if (this.skJudicalWork.results && this.skJudicalWork.results.length > 0) {
          const firstResultId = this.skJudicalWork.results[0].id
          this.filteredDate({ id: firstResultId })
        }
      })
      .catch(error => {
        console.error('An error occurred:', error)
      })
  },
  methods: {
    ...mapActions('entity', ['FETCH_SK_JUDICAL_WORK', 'FETCH_SK_JUDICAL_WORK_ID', 'FETCH_SK_LEGAL_ADJUDGEMENT', 'UPDATE_SK_LEGAL_ADJUDGEMENT']),
    ...mapActions('cases', ['CREATE_DEBT_DOCUMENT', 'UPDATE_DEBT_DOCUMENT', 'DELETE_DEBT_DOCUMENT', 'FETCH_DEBT_DOCUMENT']),

    // *********************** document *****************************//
    openDocModal() {
      this.$bvModal.show('modalDoc')
      this.docRefresh()
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }

      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skJudicalWorkId.CONTENT_TYPE)
      formData.append('object_id', this.skJudicalWorkId.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skJudicalWorkId.CONTENT_TYPE)
      formData.append('object_id', this.skJudicalWorkId.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.file_title = null
        this.formData.tags = []
        this.$bvModal.hide('addEditDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.skJudicalWorkId.CONTENT_TYPE,
        object_id: this.skJudicalWorkId.id,
        ...data,
      })
    },
    // ******************** document end **************************//

    updateForensicWork() {
      const { JUD_TYPE, JUD_ADDRESS, JUD_NAME } = this.skJudicalWorkId
      this.UPDATE_SK_LEGAL_ADJUDGEMENT({
        id: this.skJudicalWorkId.id,
        data: {
          JUD_TYPE,
          JUD_ADDRESS,
          JUD_NAME,
        },
      }).then(() => {
        this.forensicWork = true
        this.$_okToast()
      }).catch(e => {
        this.$_errorToast(e)
      })
    },

    filteredDate(item) {
      this.current = item.id
      this.FETCH_SK_JUDICAL_WORK_ID({ id: item.id })
        .then(() => {
          this.FETCH_SK_LEGAL_ADJUDGEMENT({ id: this.skJudicalWorkId.id })
        })
    },

    refresh() {
      this.FETCH_SK_LEGAL_ADJUDGEMENT({ id: this.skJudicalWorkId.id })
    },

    openActModal() {
      this.$nextTick(() => {
        this.$bvModal.show('judicalActId2')
      })
    },
  },
}
</script>
<style>
.activeList {
  background: #2639BB !important;
  color: #fff !important;
}

.activeList svg:nth-child(1) {
  color: #fff;
}
</style>
