<template>
  <Collapser
    id="personalAccount"
    open
    title="Лицевые счета"
  >
    <b-card>
      <b-row>
        <b-col md="2">
          <PersonalAccountsList
            :active-list="activeList"
            @changeList="changeList"
          />
        </b-col>
        <b-col
          v-if="activeList"
          md="10"
          :style="tabIsActive ? '' : 'height: 500px'"
        >
          <b-tabs
            v-if="tabIsActive"
          >
            <b-tab title="Информация по долгу">
              <DebtInformation :active-list="activeList" />
            </b-tab>
            <b-tab
              lazy
              title="Претензионно-судебная работа"
            >
              <ClaimsLitigationWork :active-list="activeList" />
            </b-tab>
            <b-tab
              lazy
              title="ССП"
            >
              <SSP :active-list="activeList" />
            </b-tab>
          </b-tabs>
        </b-col>
        <p
          v-else
          style="position:absolute; left: 45%; top: 10px"
          class="text-center"
        >
          Нет данных
        </p>
      </b-row>
    </b-card>
  </Collapser>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Collapser from '../Collapser.vue'
import PersonalAccountsList from './PersonalAccountsList.vue'
import DebtInformation from './DebtInformation.vue'
import ClaimsLitigationWork from './ClaimsLitigationWork.vue'
import SSP from './Spp/index.vue'

export default {
  components: {
    // bootstrap
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    // components
    Collapser,
    PersonalAccountsList,
    DebtInformation,
    ClaimsLitigationWork,
    SSP,
  },
  data() {
    return {
      activeList: 0,
      tabIsActive: true,
    }
  },
  computed: {
    ...mapState('entity', ['skLegalDetail']),
  },
  watch: {
    'skLegalDetail.sk_borrower_debt': {
      handler(val) {
        if (val && val.length) {
          this.activeList = val?.[0]
          this.SET_ACTIVE_ID(val?.[0])
          this.FETCH_SK_LEGAL_DEBT({ id: val?.[0] })
        }
      },
    },
  },
  methods: {
    ...mapActions('entity', ['FETCH_SK_LEGAL_DEBT']),
    ...mapMutations('entity', ['SET_ACTIVE_ID', 'SET_SKLEGAL_EXECUTIVE_WORK_MEPTY', 'SET_SKLEGAL_EXECUTIVE_DOCUMENT_ID_EMPTY']),

    async changeList(id) {
      try {
        this.SET_SKLEGAL_EXECUTIVE_WORK_MEPTY()
        this.SET_SKLEGAL_EXECUTIVE_DOCUMENT_ID_EMPTY()
        this.SET_ACTIVE_ID(id)
        this.tabIsActive = false
        this.activeList = id
        await this.FETCH_SK_LEGAL_DEBT({ id })
        this.tabIsActive = true
      } catch (e) {
        this.tabIsActive = true
      }
    },
  },
}
</script>
