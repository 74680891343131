<template>
  <b-modal
    id="enforcementModal"
    :ok-variant="'primary'"
    ok-title="Сохранить"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
  >
    <div class="text-right w-100">
      <b-button
        class="rounded-circle p-50"
        size="sm"
        variant="secondary"
        @click="enfPro = !enfPro"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
    </div>
    <div class="text-center">
      <h2 class="mb-2">
        Основное
      </h2>

      <div
        v-for="item in fields.enf"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center justify-content-between"
      >
        <p class="mb-0 text-left">
          {{ item.name }}
        </p>
        <b-form-input
          v-model="itemValue[item.unique_name]"
          :disabled="enfPro"
          :class="'ml-1 w-auto'"
        />
      </div>
    </div>
    <!--    <pre>{{ itemValue }}</pre>-->
  </b-modal>
</template>

<script>
import { BButton, BModal, BFormInput } from 'bootstrap-vue'
import constants from '@/modules/entity/constants/ssp'

export default {
  components: { BButton, BModal, BFormInput },
  props: {
    itemValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: {
        enf: constants.enfoPro,
      },
      items: {
        enfProItems: constants.enfoProItems,
      },
      enfPro: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
