<template>
  <div class="header-card d-flex justify-content-between align-items-center rounded-lg pt-75 pb-50 px-1">
    <h6>{{ title }}</h6>
    <div v-if="isTrue">
      <b-button
        v-if="isSave"
        variant="flat-warning"
        class="p-50 rounded-circle"
        @click="openDetailModal"
      >
        <feather-icon
          icon="EditIcon"
          size="16"
          class="text-warning cursor-pointer"
        />
      </b-button>

      <div
        v-else
        class="d-flex align-items-center"
      >
        <b-button
          variant="flat-danger"
          class="p-50 rounded-circle"
          @click="openDetailModal"
        >
          <feather-icon
            icon="XIcon"
            size="16"
            class="text-danger cursor-pointer"
          />
        </b-button>

        <b-button
          variant="flat-success"
          class="p-50 rounded-circle"
          @click="$emit('updateColumns')"
        >
          <feather-icon
            icon="CheckIcon"
            size="16"
            class="text-success cursor-pointer"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isSave: {
      type: Boolean,
      default: true,
    },
    isTrue: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    openDetailModal() {
      this.$emit('openDetailModal')
    },
  },
}
</script>

<style>
.header-card {
  background: #3646af10;
}
</style>
