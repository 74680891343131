<template>
  <b-row>
    <b-col md="6">
      <all :active-list="activeList" />
    </b-col>
    <b-col md="6">
      <executiveDocument :active-list="activeList" />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import all from './all.vue'
import executiveDocument from './executiveDocument.vue'

export default {
  components: {
    BRow,
    BCol,
    all,
    executiveDocument,
  },
  props: {
    activeList: {
      type: Number,
      default: () => 0,
    },
  },
}
</script>
