export default {
  forensicWork: [
    {
      name: 'Дата отправки заявления в суд:',
      unique_name: 'JUD_DATE_SENT',
    },
    {
      name: 'Вид последнего судебного заявления:',
      unique_name: 'JUD_TYPE',
    },
    {
      name: 'Адрес суда с индексом:',
      unique_name: 'JUD_ADDRESS',
    },
    {
      name: 'Наименование суда:',
      unique_name: 'JUD_NAME',
    },
    {
      name: 'Cумма исковых требований, указанных в заявлении:',
      unique_name: 'AMOUNT_CLAIMS',
    },
    {
      name: 'Cумма оплаченной ГП:',
      unique_name: 'JUD_GP',
    },
    {
      name: 'Наличие судебного решения:',
      unique_name: 'has_adjudgement',
    },
    {
      name: 'Дата судебного решения:',
      unique_name: 'adjudgement_date',
    },
    {
      name: 'Сумма решения:',
      unique_name: 'adjudgement_sum',
    },
    {
      name: 'Документы:',
      unique_name: 'document',
    },
    {
      name: 'Субсидиарная ответственность:',
      unique_name: 'VICARIOUS_LIABILITY',
    },
  ],

  // items
  forensicWorkItems: {
    do1: 'Москва',
    vp: 'Вид 1',
    aci: 'Петровский переулок, 1/30с1 / 100208',
    nc: 'Московский суд',
    cummis: '10 000 000 руб.',
    summaop: '1 000 000 руб.',
    ncr: 'Есть',
    dc: '01.01.2025',
    cr: '100 000 000 руб.',
    document: '',
    responible: '',
  },
}
