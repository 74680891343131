<template>
  <Collapser
    id="statement"
    open
    title="Выписка по должнику"
  >
    <b-card>
      <b-row>
        <b-col md="4">
          <EntityCardHeader
            title="Общее"
            :is-save="all"
            :is-true="false"
            @openDetailModal="all = !all"
            @updateColumns="updateAll(fields.general)"
          />
          <div
            v-for="item in fields.general"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="all ? '' : 'justify-content-between'"
          >
            <p class="mb-0">
              {{ item.name }}<!-- <b>{{ items.generalItems[item.unique_name] }}</b>-->
            </p>

            <div v-if="item.unique_name === 'REGISTRATION_DATE'">
              <flat-pickr
                v-if="!all"
                v-model="skLegalDetail[item.unique_name]"
                class="form-control"
                :config="configdateTimePicker"
              />
              <span
                v-else
                :class="all ? 'ml-50 font-weight-bold w-50 text-dark' : 'ml-1 w-50'"
              >
                {{ skLegalDetail[item.unique_name] }}
              </span>
            </div>

            <b-form-input
              v-else
              v-model="skLegalDetail[item.unique_name]"
              :plaintext="all"
              :class="all ? 'ml-50 font-weight-bold w-50' : 'ml-1 w-50'"
            />
          </div>
        </b-col>

        <b-col md="4">
          <EntityCardHeader
            title="Персональные данные"
            :is-save="personal"
            @openDetailModal="personal = !personal"
            @updateColumns="updatePersonal(fields.personalData)"
          />
          <div
            v-for="item in fields.personalData"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="personal ? '' : 'justify-content-between'"
          >
            <p class="mb-0">{{ item.name }}
            </p>
            <b-form-input
              v-if="item.unique_name === 'HOME_ADDRESS'"
              v-model="skLegalDetail[item.unique_name]"
              :plaintext="personal"
              :class="personal ? 'font-weight-bold w-50 text-right ml-auto' : 'ml-1 w-50 text-right ml-auto'"
            />
            <p
              v-else
              style="
                 width: 60%;
               margin-left: auto;"
              class="text-right"
            >
              {{ skLegalDetail[item.unique_name] }}
            </p>
          </div>
        </b-col>

        <b-col md="4">
          <EntityCardHeader
            title="Контакты"
            :is-true="false"
            :is-save="contacts"
            @openDetailModal="contacts = !contacts"
            @updateColumns="updateContact(fields.contacts)"
          />

          <div
            v-for="(e, index) in skLegalDetail.contact_phones"
            v-show="skLegalDetail.contact_phones"
            :key="index"
            class="mt-50 d-flex align-items-center"
            :class="contacts ? '' : 'd-flex justify-content-between'"
          >
            <div>{{ e.TYPE_CONTACT }}:</div>
            <span class="ml-50 font-weight-bold w-50 text-info">{{ e.PHONE }}</span>
          </div>

          <div
            v-for="item in fields.contacts"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="contacts ? '' : 'justify-content-between'"
          >
            <p class="mb-0">
              {{ item.name }}
            </p>

            <!--            <b-form-input-->
            <!--              v-if="item.unique_name !== 'EMAIL'"-->
            <!--              v-model="skLegalDetail[item.unique_name]"-->
            <!--              :plaintext="contacts"-->
            <!--              :class="contacts ? 'ml-50 font-weight-bold w-50 text-info' : 'ml-1 w-50'"-->
            <!--            />-->

            <p
              v-for="(e, ind) in skLegalDetail.EMAIL"
              v-show="item.unique_name === 'EMAIL'"
              :key="ind"
              class="text-right"
            >
              <a
                :href="'mailto:' + e"
                target="_blank"
                class="ml-50"
              >
                {{ e }}
              </a>
            </p>
          </div>
        </b-col>

        <!--legal_contacts-->
        <b-col
          md="4"
          class="mt-2"
        >
          <EntityCardHeader
            :is-true="false"
            title="Контактные лица"
            :is-save="ContactFaces"
            @openDetailModal="ContactFaces = !ContactFaces"
            @updateColumns="updateAll(items.contactFacesItems)"
          />
          <div
            v-for="item in skLegalDetail.legal_contacts"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="ContactFaces ? '' : 'justify-content-between'"
          >
            <p class="mb-0">
              {{ item.POSITION }}
            </p>
            <b-form-input
              v-model="item.CONTACT"
              :plaintext="ContactFaces"
              :class="ContactFaces ? 'ml-50 font-weight-bold w-75 text-info' : 'ml-1 w-75'"
              @input="updateAll(item)"
              @click="getName(item.CONTACT)"
            />
          </div>
        </b-col>

        <b-col
          md="4"
          class="mt-2"
        >
          <EntityCardHeader
            :is-true="false"
            title="Финансовое состояние"
            :is-save="financialCondition"
            @openDetailModal="financialCondition = !financialCondition"
            @updateColumns="updateFinance(fields.financialCondition)"
          />
          <div
            v-for="item in fields.financialCondition"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="financialCondition ? '' : 'justify-content-between'"
          >
            <p class="mb-0">
              {{ item.name }}
            </p>
            <p
              v-if="item.unique_name === 'pr'"
              class="mb-0 ml-50"
            >
              <span v-if="skLegalDetail.COMPANY_PROFIT && skLegalDetail.COMPANY_REVENUE">
                {{ skLegalDetail.COMPANY_PROFIT }}/
                {{ skLegalDetail.COMPANY_REVENUE }}
              </span>
            </p>
            <p
              v-if="item.unique_name === 'ish'"
              class="mb-0 ml-50 text-info"
              @click="openPropertyModal"
            >
              Открыть
            </p>
            <div
              v-if="item.unique_name === 'count'"
              class="mb-0 ml-50"
            >
              <b-form-input
                v-model="skLegalDetail.debt_info.total_count"
                :plaintext="financialCondition"
                :class="financialCondition ? 'ml-50 font-weight-bold w-50' : 'w-100'"
              />
            </div>
            <div
              v-if="item.unique_name === 'sum'"
              class="mb-0 ml-50"
            >
              <b-form-input
                v-model="skLegalDetail.debt_info.total_debt"
                :plaintext="financialCondition"
                :class="financialCondition ? 'ml-50 font-weight-bold w-50' : 'w-100'"
              />
            </div>
            <b-form-input
              v-else
              v-model="skLegalDetail[item.unique_name]"
              :plaintext="financialCondition"
              :style="item.unique_name === 'count' || item.unique_name === 'sum'|| item.unique_name === 'ish' ? 'display: none' : ''"
              :class="financialCondition ? 'ml-50 font-weight-bold w-50' : 'ml-1 w-50'"
            />
          </div>
        </b-col>

        <b-col
          md="4"
          class="mt-2"
        >
          <EntityCardHeader
            :is-true="false"
            title="Банкротство"
            :is-save="bankruptcyItems"
            @openDetailModal="bankruptcyItems = !bankruptcyItems"
            @updateColumns="updateAll(fields.bankruptcy)"
          />
          <div
            v-for="item in fields.bankruptcy"
            :key="item.unique_name"
            class="mt-50 d-flex align-items-center"
            :class="bankruptcyItems ? '' : 'justify-content-between'"
          >
            <p class="mb-0">
              {{ item.name }}<!--<b>{{ items.bankruptcyItems[item.unique_name] }}</b>-->
            </p>
            <!--            <b-form-input-->
            <!--              v-model="items.bankruptcyItems[item.unique_name]"-->
            <!--              :plaintext="bankruptcyItems"-->
            <!--              :class="bankruptcyItems ? 'ml-50 font-weight-bold w-50' : 'ml-1 w-50'"-->
            <!--            />-->
            <b class="ml-50 font-weight-bold w-50">
              -
            </b>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <TheDebtorPropertyModal
      ref="propertyModal"
      @propertyRefresh="propertyRefresh"
    />
  </Collapser>
</template>

<script>
import constants from '@/modules/entity/constants'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import TheDebtorPropertyModal from '@/modules/statementDebtor/components/TheDebtorPropertyModal.vue'
import Collapser from './Collapser.vue'
import EntityCardHeader from './EntityCardHeader.vue'

export default {
  components: {
    TheDebtorPropertyModal,
    // bootstrap
    BCard,
    BRow,
    BCol,
    BFormInput,
    flatPickr,
    // components
    Collapser,
    EntityCardHeader,
  },

  data() {
    return {
      fields: {
        general: constants.general,
        personalData: constants.personalData,
        contacts: constants.contacts,
        contactFaces: constants.contactFaces,
        financialCondition: constants.financialCondition,
        bankruptcy: constants.bankruptcy,
      },
      items: {
        contactFacesItems: constants.contactFacesItems,
        bankruptcyItems: constants.bankruptcyItems,
      },
      all: true,
      personal: true,
      contacts: true,
      ContactFaces: true,
      financialCondition: true,
      bankruptcyItems: true,
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('entity', ['skLegalDetail']),
  },

  methods: {
    ...mapActions('entity', ['UPDATE_SK_LEGAL']),
    ...mapActions('cases', ['FETCH_DEBT_PROPERTY_MODAL']),

    updateAll() {
      const { FULL_NAME, REGISTRATION_DATE, REGION } = this.skLegalDetail

      this.UPDATE_SK_LEGAL({
        id: this.$route.params.id,
        data: { FULL_NAME, REGISTRATION_DATE, REGION },
      })
      this.all = true
    },

    updatePersonal() {
      // INN, OGRN, PERM_REG, ADDRESS_YL,
      const {
        HOME_ADDRESS,
      } = this.skLegalDetail

      this.UPDATE_SK_LEGAL({
        id: this.$route.params.id,
        data: {
          HOME_ADDRESS,
        },
      })
      this.personal = true
    },

    updateContact() {
      const { EMAIL, WEB_SITE } = this.skLegalDetail

      this.UPDATE_SK_LEGAL({
        id: this.$route.params.id,
        data: { EMAIL, WEB_SITE },
      })
      this.contacts = true
    },

    // place of ContactFaces

    updateFinance() {
      const { AUTHORIZED_CAPITAL, TAX_SYSTEM } = this.skLegalDetail

      this.UPDATE_SK_LEGAL({
        id: this.$route.params.id,
        data: { AUTHORIZED_CAPITAL, TAX_SYSTEM },
      })
      this.financialCondition = true
    },

    getName(name) {
      this.$router.push({
        name: 'cases-home',
        query: { name },
      })
    },

    openPropertyModal() {
      this.$refs.propertyModal.open()
    },

    propertyRefresh(data) {
      this.FETCH_DEBT_PROPERTY_MODAL({
        id: this.$route.params.id,
        ...data,
      })
    },
  },
}
</script>
<style>
@import '~flatpickr/dist/flatpickr.css';
</style>
