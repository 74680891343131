export default {
  // fields
  general: [
    {
      name: 'Наименование ЮЛ:',
      unique_name: 'FULL_NAME',
    },
    {
      name: 'Дата регистрации ЮЛ:',
      unique_name: 'REGISTRATION_DATE',
    },
    {
      name: 'Наименование ТО/регион:',
      unique_name: 'REGION',
    },
  ],
  personalData: [
    {
      name: 'ИНН:',
      unique_name: 'INN',
    },
    {
      name: 'ОГРН/ОГРН ИП:',
      unique_name: 'OGRN',
    },
    {
      name: 'Место регистрации:',
      unique_name: 'PERM_REG',
    },
    {
      name: 'Юридический адрес:',
      unique_name: 'ADDRESS_YL',
    },
    {
      name: 'Фактический адрес:',
      unique_name: 'HOME_ADDRESS',
    },
  ],
  contacts: [
    {
      name: 'E-mail:',
      unique_name: 'EMAIL',
    },
    {
      name: 'Сайт:',
      unique_name: 'WEB_SITE',
    },
  ],
  contactFaces: [
    {
      name: 'Учредитель:',
      unique_name: 'founder',
    },
    {
      name: 'Генеральный директор:',
      unique_name: 'gen',
    },
    {
      name: 'Директор:',
      unique_name: 'doctor',
    },
    {
      name: 'Главный бухгалтер:',
      unique_name: 'bug',
    },
  ],
  financialCondition: [
    {
      name: 'Уставной капитал:',
      unique_name: 'AUTHORIZED_CAPITAL',
    },
    {
      name: 'Система налогообложения:',
      unique_name: 'TAX_SYSTEM',
    },
    {
      name: 'Прибыль компании/выручка:',
      unique_name: 'pr',
    },
    {
      name: 'Движения по счетам:',
      unique_name: 'dv',
    },
    {
      name: 'Имущество:',
      unique_name: 'ish',
    },
    {
      name: 'Количество долгов:',
      unique_name: 'count',
    },
    {
      name: 'Сумма долгов:',
      unique_name: 'sum',
    },
  ],
  bankruptcy: [
    {
      name: 'Дата признания банкротом:',
      unique_name: 'bankruptcy_date',
    },
    {
      name: 'Номер дела  банкрота:',
      unique_name: 'num',
    },
    {
      name: 'Количество судебных дел:',
      unique_name: 'del',
    },
  ],

  // items
  contactFacesItems: {
    founder: 'Шамов Максим Герасимович',
    gen: 'Шамов Максим Герасимович',
    doctor: 'Шамов Максим Герасимович',
    bug: 'Шамов Максим Герасимович',
  },
  bankruptcyItems: {
    bankruptcy_date: '01.01.2024',
    num: '1234567890',
    del: '3 000 000 руб. / 1 000 000 руб.',
  },
}
