<template>
  <div>
    <div class="">
      <!--      :is-true="sklegalExecutiveDocumentId && sklegalExecutiveDocumentId.id"-->
      <EntityCardHeader
        class="mt-1"
        title="Исполнительный документ"
        :is-save="all"
        :is-true="false"
        @openDetailModal="all = !all"
        @updateColumns="updateColumnsAll"
      />

      <div
        class="mt-50 d-flex align-items-center"
        :class="all ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          Тип:
        </p>
        <div class="w-50">
          <v-select
            v-if="!all"
            v-model="sklegalExecutiveDocumentId.TYPE"
            class="w-100"
            label="title"
            :reduce="p => p.value"
            :options="TYPE_CCP"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p
            v-else
            class="mb-0 text-dark ml-50"
          >
            {{ type2 }}
          </p>
        </div>
      </div>

      <div
        v-for="item in fields.exDoc"
        :key="item.unique_name"
        class="mt-50 d-flex align-items-center"
        :class="all ? '' : 'justify-content-between'"
      >
        <p class="mb-0">
          {{ item.name }}
        </p>
        <!--if unique_name is equal to 'document || comment'-->
        <p
          v-if="item.unique_name === 'document' && sklegalExecutiveDocumentId.id"
          class="mb-0 text-info ml-1 cursor-pointer"
          @click="openDocModal"
        >
          Документы
        </p>

        <div v-if="item.unique_name === 'ADJUDGEMENT_DOCUMENT_DATE'">
          <flat-pickr
            v-if="!all"
            v-model="sklegalExecutiveDocumentId.ADJUDGEMENT_DOCUMENT_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p
            v-else
            class="ml-50"
          >
            <span>{{ sklegalExecutiveDocumentId.ADJUDGEMENT_DOCUMENT_DATE }}</span>
          </p>
        </div>

        <div v-if="item.unique_name === 'DELAY_DATE'">
          <flat-pickr
            v-if="!all"
            v-model="sklegalExecutiveDocumentId.DELAY_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p
            v-else
            class="ml-50"
          >
            <span>{{ sklegalExecutiveDocumentId.DELAY_DATE }}</span>
          </p>
        </div>

        <div v-if="item.unique_name === 'RETURN_DATE'">
          <flat-pickr
            v-if="!all"
            v-model="sklegalExecutiveDocumentId.RETURN_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p
            v-else
            class="ml-50"
          >
            <span>{{ sklegalExecutiveDocumentId.RETURN_DATE }}</span>
          </p>
        </div>

        <div v-if="item.unique_name === 'PRESENT_DATE'">
          <flat-pickr
            v-if="!all"
            v-model="sklegalExecutiveDocumentId.PRESENT_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p
            v-else
            class="ml-50"
          >
            <span>{{ sklegalExecutiveDocumentId.PRESENT_DATE }}</span>
          </p>
        </div>

        <p
          v-if="item.unique_name === 'CONTACT'"
          class="mb-0 text-info ml-1"
        >
          {{ sklegalExecutiveDocumentId.CONTACT }}
        </p>
        <b-form-input
          v-model="sklegalExecutiveDocumentId[item.unique_name]"
          :style="
            item.unique_name === 'document'
              || item.unique_name === 'CONTACT'
              || item.unique_name === 'ADJUDGEMENT_DOCUMENT_DATE'
              || item.unique_name === 'DELAY_DATE'
              || item.unique_name === 'RETURN_DATE'
              || item.unique_name === 'PRESENT_DATE'
              ? 'display: none' : ''"
          :plaintext="all"
          :class="all ? 'ml-50 font-weight-bold w-25' : 'ml-1 w-auto'"
        />
      </div>
    </div>

    <enforcementProceedings />

    <theDocClaimsModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import EntityCardHeader from '@/modules/entity/pages/components/EntityCardHeader.vue'
import constants from '@/modules/entity/constants/ssp'
import enforcementProceedings from '@/modules/entity/pages/components/personalAccounts/Spp/enforcementProceedings.vue'
import { mapActions, mapState } from 'vuex'
import theDocClaimsModal from '@/modules/claimsLitigation/claimsModal/doc/theDocClaimsModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import { Russian } from 'flatpickr/dist/l10n/ru'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    vSelect,
    theDocClaimsModal,
    BFormInput,
    EntityCardHeader,
    enforcementProceedings,
    flatPickr,
  },
  data() {
    return {
      fields: {
        exDoc: constants.exDoc,
      },
      items: {
        exDocItems: constants.exDocItems,
      },
      all: true,
      type: null,
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('entity', ['sklegalExecutiveDocumentId']),
    ...mapState('cases', ['debtDocument', 'TYPE_CCP']),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    type2() {
      let typeText = ''
      // eslint-disable-next-line default-case
      switch (this.sklegalExecutiveDocumentId.TYPE) {
        case 'executive_list':
          typeText = 'Исполнительный лист'
          break
        case 'court_order':
          typeText = 'Судебный приказ'
          break
        case 'executive_inscription':
          typeText = 'Исполнительная надпись'
          break
      }
      return typeText
    },
  },
  methods: {
    ...mapActions('cases', ['CREATE_DEBT_DOCUMENT', 'UPDATE_DEBT_DOCUMENT', 'DELETE_DEBT_DOCUMENT', 'FETCH_DEBT_DOCUMENT']),
    ...mapActions('entity', ['UPDATE_SK_LEGAL_EX_DOCUMENT']),

    updateColumnsAll() {
      const {
        TYPE, EXECUTIVE_DATE, ADJUDGEMENT_DOCUMENT_DATE, EXECUTIVE_NUMBER, PRESENT_DATE, CONTACT, DELAY_DATE, RETURN_DATE, COMMENT,
      } = this.sklegalExecutiveDocumentId

      this.UPDATE_SK_LEGAL_EX_DOCUMENT({
        id: this.sklegalExecutiveDocumentId.id,
        data: {
          TYPE, EXECUTIVE_DATE, ADJUDGEMENT_DOCUMENT_DATE, EXECUTIVE_NUMBER, PRESENT_DATE, CONTACT, DELAY_DATE, RETURN_DATE, COMMENT,
        },
      }).then(() => {
        this.all = true
        this.$_okToast()
      }).catch(e => {
        this.$_errorToast(e)
      })
    },

    // ******************** document **************************//
    openDocModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modalClaimsDoc')
      })
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData.file) {
        this.formData.file.forEach(key => {
          if (key) formData.append('file', key)
        })
      }
      if (this.formData.tags) {
        this.formData.tags.forEach(key => {
          if (key) formData.append('tags', key)
        })
      }

      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.sklegalExecutiveDocumentId.CONTENT_TYPE)
      formData.append('object_id', this.sklegalExecutiveDocumentId.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }

      if (this.formData?.tags) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.sklegalExecutiveDocumentId.CONTENT_TYPE)
      formData.append('object_id', this.sklegalExecutiveDocumentId.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.tags = []
        this.formData.file_title = null
        this.$bvModal.hide('addEdiClaimstDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_DEBT_DOCUMENT(id)
            .then(() => {
              this.docRefresh()
              this.$_okToast()
            })
            .catch(err => {
              this.$_errorToast(err)
            })
        }
      })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.sklegalExecutiveDocumentId.CONTENT_TYPE,
        object_id: this.sklegalExecutiveDocumentId.id,
        ...data,
      })
    },
    // ******************** document **************************//
  },
}
</script>
<style>
@import '~flatpickr/dist/flatpickr.css';
</style>
