<template>
  <div>
    <b-button
      class="mb-1"
      @click="$router.push('/sk-entity')"
    >
      <svg
        fill="currentColor"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        class="material-design-icon__svg"
      ><path d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z" /></svg>
      Назад
    </b-button>

    <EntityHeader />
    <DebtorStatement />
    <PersonalAccounts />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import EntityHeader from './components/EntityHeader.vue'
import DebtorStatement from './components/DebtorStatement.vue'
import PersonalAccounts from './components/personalAccounts/PersonalAccounts.vue'

export default {
  components: {
    BButton,
    EntityHeader,
    DebtorStatement,
    PersonalAccounts,
  },
  computed: {
    ...mapState('entity', ['skLegalDetail']),
  },
  mounted() {
    this.FETCH_SK_LEGAL_DETAIL({ id: this.$route.params.id })
  },
  methods: {
    ...mapActions('entity', ['FETCH_SK_LEGAL_DETAIL']),
  },
}
</script>
