<template>
  <div>
    <b-card class="cursor-pointer" @click="toggleContents">
      <header class="d-flex align-items-center">
        <b-button variant="outline-primary" class="mr-1 p-25">
          <feather-icon :icon="collapseContent ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </b-button>
        <h5 class="text-primary mt-50">{{ title }}</h5>
      </header>
    </b-card>
    <b-collapse class="test" :id="id" v-model="collapseContent">
      <slot />
    </b-collapse>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
  BCollapse
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BCollapse
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      collapseContent: true
    }
  },

  mounted() {
    this.collapseContent = this.open
  },

  methods: {
    toggleContents() {
      this.collapseContent = !this.collapseContent
    }
  }
}
</script>

<style>
.test {
  margin-top: -12px;
}
</style>