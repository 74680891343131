<template>
  <div>
    <b-card v-if="SetSkLegalDebt.CLAIMER_COMPANY_NAME && SetSkLegalDebt.TOTAL_DEBT_LEGAL_CONTACT">
      <h3 class="text-center">
        {{ SetSkLegalDebt.CLAIMER_COMPANY_NAME }} / <b>{{ $_moneyFormatter(SetSkLegalDebt.TOTAL_DEBT_LEGAL_CONTACT) }}</b>
      </h3>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="5">
          <p class="p-style">
            Наличие письменного заявления об отказе от взаимодействия: <a class="text-info">-</a>
          </p>
          <p class="p-style">
            Наличие скан.копии свидетельства о смерти: <a class="text-info">-</a>
          </p>
          <p class="p-style">
            Стадия: <b>{{ SetSkLegalDebt.DO_STAGE }}</b>
          </p>
          <p class="p-style">
            Стадия ликвидации: <b>-</b>
          </p>
          <p class="p-style">
            Стадия банкротства: <b>{{ SetSkLegalDebt.BANKRUPT }}</b>
          </p>
        </b-col>
        <b-col md="4">
          <div
            v-for="(tag, index) in skTags.results"
            :key="index"
            class="d-flex justify-content-between w-100"
          >
            <label
              class="p-style"
              :for="'checkbox_' + index"
            >
              {{ tag.name }}
            </label>
            <BFormCheckbox
              :id="'checkbox_' + index"
              :checked="TAGS.includes(tag.id)"
              @change="checkedTag($event, tag)"
            />
          </div>
        </b-col>
        <b-col
          md="3"
          class="d-flex justify-content-center align-items-center"
        >
          <div>
            <b-button
              class="d-flex justify-content-center align-items-center mb-1 w-100 p-2"
              variant="success"
              @click="updateTags"
            >
              <feather-icon
                icon="CheckIcon"
                size="20"
                class="mr-50"
              />
              <span>Сохранить теги</span>
            </b-button>

            <b-button
              class="d-flex justify-content-center align-items-center w-100 p-2"
              variant="info"
              @click="openCaseChangeHistory"
            >
              <feather-icon
                icon="EyeIcon"
                size="20"
                class="mr-50"
              />
              <span>История изменения дел</span></b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <historyModal
      ref="caseChangeHistory"
      :load-his="loadHis"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import historyModal from '@/modules/entity/components/historyModal.vue'

export default {
  components: {
    historyModal,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      TAGS: [],
      loadHis: false,
    }
  },
  computed: {
    ...mapState('entity', ['skTags', 'SetSkLegalDebt', 'activeId']),
  },
  watch: {
    'SetSkLegalDebt.TAG': {
      handler(val) {
        if (val) {
          this.TAGS = this.SetSkLegalDebt.TAG
        }
      },
    },
  },
  mounted() {
    this.FETCH_SK_TAGS()
  },
  methods: {
    ...mapActions('entity', ['FETCH_SK_TAGS', 'UPDATE_SK_LEGAL_DEBT']),
    ...mapActions('cases', ['FETCH_SK_DEBT_HISTORY']),

    updateTags() {
      this.UPDATE_SK_LEGAL_DEBT({
        id: this.SetSkLegalDebt.id,
        TAG: this.TAGS,
      })
        .then(() => {
          this.FETCH_SK_TAGS()
          this.$_okToast()
        })
    },

    checkedTag(val, card) {
      let replaceIndex
      if (val) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.TAGS) {
          if (card.id > key) {
            replaceIndex = this.TAGS.indexOf(key) + 1
          }
        }

        this.TAGS.splice(replaceIndex, 0, card.id)
      } else {
        const deleteItemIndex = this.TAGS.indexOf(card.id)
        this.TAGS.splice(deleteItemIndex, 1)
      }
    },

    async openCaseChangeHistory() {
      this.$refs.caseChangeHistory.open()
      await this.refresh()
    },

    async refresh(data) {
      try {
        this.$refs.caseChangeHistory.open()
        this.loadHis = true
        await this.FETCH_SK_DEBT_HISTORY({ id: this.activeId, ...data })
      } catch (error) {
        await this.$_errorToast()
      } finally {
        this.loadHis = false
      }
    },
  },
}
</script>
<style scoped>
.p-style {
  line-height: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
</style>
