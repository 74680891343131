<template>
  <div>
    <!--    <pre>{{ sklegalExecutiveWork }}</pre>-->
    <!--    <div class="rounded border w-100 p-1">-->

    <!--      <BButton-->
    <!--        v-for="(item, index) in sklegalExecutiveWork.results"-->
    <!--        :key="index"-->
    <!--        class="border mr-1"-->
    <!--        variant="light"-->
    <!--        :class="{'activeList' : current === item.id}"-->
    <!--        @click="filteredDate(item)"-->
    <!--      >-->
    <!--        Работа от-->
    <!--      </BButton>-->
    <!--    </div>-->
    <EntityCardHeader
      v-if="sklegalExecutiveWork.results && sklegalExecutiveWork.results.length"
      title="Исполнительное производство"
      :is-save="enfPro"
      :is-true="false"
      @openDetailModal="enfPro = !enfPro"
    />

    <div
      v-for="(item, index) in sklegalExecutiveWork.results"
      :key="index"
      class="d-flex align-items-center mt-50"
    >
      <p class="mb-0">
        ИП от {{ item.SSP_IP_START_DATE }}:
      </p>
      <p
        v-if="item.id"
        class="mb-0 text-info ml-1 cursor-pointer"
        @click="openItem(item)"
      >
        Открыть
      </p>
    </div>

    <enforcementModal :item-value="itemValue" />
  </div>
</template>
<script>
import EntityCardHeader from '@/modules/entity/pages/components/EntityCardHeader.vue'
import { mapActions, mapState } from 'vuex'
import enforcementModal from './enforcementModal.vue'

export default {
  components: {
    EntityCardHeader,
    enforcementModal,
  },
  data() {
    return {
      current: null,
      itemValue: {},
      enfPro: true,
    }
  },
  computed: {
    ...mapState('entity', ['sklegalExecutiveWork', 'sklegalExecutiveDocumentId']),
  },
  watch: {
    'sklegalExecutiveDocumentId.id': {
      handler(val) {
        if (val) {
          this.FETCH_SKLEGAL_EXECUTIVE_WORK({ EXECUTIVE_DOC_ID: val })
        }
      },
    },
  },
  methods: {
    ...mapActions('entity', ['FETCH_SKLEGAL_EXECUTIVE_WORK']),

    openItem(value) {
      this.itemValue = value
      this.$nextTick(() => {
        this.$bvModal.show('enforcementModal')
      })
    },
    // filteredDate(item) {
    //   this.current = item.id
    // },
  },
}
</script>
