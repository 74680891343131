<template>
  <BModal
    v-model="modalState"
    size="xl"
    centered
    title="История изменения дела"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    no-close-on-backdrop
  >
    <BTable
      hover
      class="my-2 overflow-auto table-bordered"
      :fields="fields"
      :items="items"
      show-empty
      :busy="loadHis"
      responsive
      empty-text="Нет данных"
    >
      <template #table-busy>
        <div class="d-flex align-items-center flex-column p-3">
          <b-spinner
            variant="primary"
          />
          <span>Загрузка...</span>
        </div>
      </template>
      <template #cell(created_at)="props">
        <span class="font-weight-bolder mb-25">
          {{ props.item.created_at }}
        </span>
      </template>
      <template #cell(user)="props">
        <div
          v-if="props.item.user"
          class="d-flex align-items-center"
        >
          <b-avatar
            v-b-tooltip.hover
            class="pull-up"
            :title="props.item.user.first_name"
            :src="props.item.user.profile_picture"
          />
          <div class="ml-50">
            <h6 class="mb-0">
              {{ props.item.user.first_name }}
            </h6>
            <small>{{ props.item.user.last_name }}</small>
          </div>
        </div>
      </template>

      <template #cell(difference)="props">
        <div v-if="props.item.difference">
          <h6 class="mb-1">
            {{ props.item.action }}
          </h6>
          <div v-html="props.item.difference" />
        </div>
      </template>
    </BTable>

    <BCPagination
      v-if="debtHistory.count > 10"
      :go-to-page="true"
      :count="debtHistory.count"
      @input="onRefresh"
    />
  </BModal>
</template>

<script>
import {
  BTable,
  BModal,
  BAvatar,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import BCPagination from '@/components/BCPagination.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BTable,
    BModal,
    BAvatar,
    BCPagination,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    loadHis: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      modalState: false,
      fields: [
        {
          key: 'created_at',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'user',
          label: 'Автор',
          sortable: true,
        },
        {
          key: 'table_name',
          label: 'Блок',
        },
        {
          key: 'difference',
          label: 'Действия',
        },
      ],
      items: [],
    }
  },

  computed: {
    ...mapState('cases', ['debtHistory']),
  },

  watch: {
    'debtHistory.results': {
      handler() {
        this.getHistoryAll()
      },
    },
  },

  methods: {
    onRefresh(data) {
      this.$emit('refresh', data)
    },
    open() {
      this.modalState = true
    },
    close() {
      this.modalState = false
    },

    getHistoryAll() {
      try {
        if (this.debtHistory && this.debtHistory.results) {
          this.items = this.debtHistory.results.map(element => {
            if (element.difference) {
              if (element.request_data === null) {
                const keys = Object.keys(element.difference)

                let a = ''

                for (let i = 0; i < keys.length; i += 1) {
                  if (element.difference[keys[i]].length > 1) {
                    a += `<b>${keys[i]}: </b>${
                      element.difference[keys[i]][0]
                    }  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg> ${
                      element.difference[keys[i]][1]
                    } <br/>`
                  } else {
                    a += `<b>${keys[i]}: </b>${
                      element.difference[keys[i]][0]
                    } <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg> ${
                      element.difference[keys[i]][1]
                    } <br/>`
                  }
                }

                element.difference = a
              } else {
                return element
              }
            }

            return element
          })
        }
      } catch (e) {
        this.$_errorToast(e)
      }
    },
  },
}
</script>
