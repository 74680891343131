<template>
  <div>
    <div
      v-for="(list, index) in skLegalDetail.sk_borrower_debt"
      :key="index"
      class="d-flex justify-content-center align-items-center cursor-pointer p-1 "
      :class="activeList === list ? 'active-list' : 'inactive-list'"
      @click.stop="$emit('changeList', list)"
    >
      <div class="text-primary ">
        Лицевой счет <span class="ml-25">{{ index + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    activeList: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      title: '',
    }
  },
  computed: {
    ...mapState('entity', ['skLegalDetail']),
  },
}
</script>

<style lang="scss">
.active-list {
  border-radius: 8px;
  background: #3646af10;
  color: #3646AF !important;
}

.inactive-list:hover {
  color: #3646AF !important;
}
</style>
