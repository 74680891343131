export default {
  general: [
    {
      name: 'Валюта кредита:',
      unique_name: 'CURRENCY',
    },
    {
      name: 'Город выдачит кредита:',
      unique_name: 'CITY_NAME',
    },
    {
      name: 'Тип кредита:',
      unique_name: 'TYPE_CREDIT',
    },
    {
      name: 'Предмет договора:',
      unique_name: 'SUBJECT_AGREEMENT',
    },
    {
      name: '№ КД (айди договора):',
      unique_name: 'DEBT_TYPE',
    },
    {
      name: 'Дата выдачи кредита:',
      unique_name: 'DEBT_START_DATE',
    },
    {
      name: 'Дата окончания договора:',
      unique_name: 'DEBT_END_DATE',
    },
    {
      name: 'Сумма кредита:',
      unique_name: 'DEBT_SUM',
    },
    {
      name: 'Срок кредита:',
      unique_name: 'DEBT_PERIOD',
    },
    {
      name: 'Размер процентной ставки:',
      unique_name: 'INTEREST_RATE',
    },
  ],
  condition: [
    {
      name: 'Дата выхода на просроченную задолженность:',
      unique_name: 'DEBT_DATE',
    },
    {
      name: 'Количество дней просрочки:',
      unique_name: 'DELAY',
    },
    // {
    //   name: 'Валюта кредита:',
    //   unique_name: 'CURRENCY',
    // },
    {
      name: 'Дата последнего платежа-:',
      unique_name: 'dpp',
    },
    {
      name: 'Сумма последнего платежа-:',
      unique_name: 'cpp',
    },
    {
      name: 'Сумма задолженности по основному долгу:',
      unique_name: 'DEBT_AMOUNT',
    },
    {
      name: 'Сумма задолженности по процентам:',
      unique_name: 'DEBT_INTEREST',
    },
    {
      name: 'Сумма задолженности по неустойке признанной судом:',
      unique_name: 'DEBT_PENALTY_FOR_COURT',
    },
    {
      name: 'Сумма задолженности по комиссиям:',
      unique_name: 'DEBT_COMMISSIONS',
    },
    {
      name: 'Сумма задолженности по оборудованию, технике:',
      unique_name: 'DEBT_EQUIPMENT',
    },
    {
      name: 'Сумма задолженности по госпошлине:',
      unique_name: 'DEBT_ARREARS',
    },
    {
      name: 'Общая сумма задолженности:',
      unique_name: 'DEBT',
    },
  ],
}
