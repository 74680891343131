export default {
  all: [
    {
      name: 'Адрес ОФССП:',
      unique_name: 'SSP_OSP_ADDRESS',
    },
    {
      name: 'Наименование ОФССП:',
      unique_name: 'SSP_OSP_NAME',
    },
    {
      name: 'Наличие актов о невозможности взыскания:',
      unique_name: 'document',
    },
  ],
  sum: [
    {
      name: 'Сумма по ИД:',
      unique_name: 'EXECUTIVE_SUM',
    },
    {
      name: 'Сумма остатка:',
      unique_name: 'REST_SUM',
    },
    {
      name: 'Сумма остатка с учётом длящихся:',
      unique_name: 'REST_SUM_LONG',
    },
  ],
  exDoc: [
    {
      name: 'Дата:',
      unique_name: 'EXECUTIVE_DATE',
    },
    {
      name: 'Судебный акт:',
      unique_name: 'ADJUDGEMENT_DOCUMENT_DATE',
    },
    {
      name: 'Номер документа:',
      unique_name: 'EXECUTIVE_NUMBER',
    },
    {
      name: 'Срок предъявления:',
      unique_name: 'PRESENT_DATE',
    },
    {
      name: 'Место нахождения--:',
      unique_name: 'placeN',
    },
    {
      name: 'На кого:',
      unique_name: 'CONTACT',
    },
    {
      name: 'Документы:',
      unique_name: 'document',
    },
    {
      name: 'Отложить до:',
      unique_name: 'DELAY_DATE',
    },
    {
      name: 'Дата возврата ИД:',
      unique_name: 'RETURN_DATE',
    },
    {
      name: 'Комментарии:',
      unique_name: 'COMMENT',
    },
  ],
  enfoPro: [
    {
      name: 'ИП от:',
      unique_name: 'SSP_IP_START_DATE',
    },
    {
      name: 'Дата исполнительного листа--:',
      unique_name: 'dataIs',
    },
    {
      name: 'Дата постановления о возбуждении исполнительного производства:',
      unique_name: 'SSP_RETURN_DATE',
    },
    {
      name: 'Дата отправки исполнительного документа в ФССП:',
      unique_name: 'FSSP_UPDATE_DATE',
    },
    {
      name: 'Дата ИП:',
      unique_name: 'SSP_DATE',
    },
    {
      name: 'Номер ИП:',
      unique_name: 'SSP_NUMBER_IP',
    },
    {
      name: 'Сумма по данным взыскателя:',
      unique_name: 'CLAIMER_SUM',
    },
    {
      name: 'Сумма по данным ФССП:',
      unique_name: 'FSSP_SUM',
    },
  ],

  // items
  enfoProItems: {
    ip1: '',
    dataIs: '01.01.2024',
    dataPoc: '01.01.2024',
    addressOf: 'Петровский переулок, 1/30с1',
    daatOt: '01.01.2024',
    nameOfs: 'nameOfs',
    dataIp: '01.01.2024',
    NumIp: '1234567890',
    ipOt: '',
    sumpodo: '100 000 000 руб.',
    sumOPo: '100 000 000 руб.',
  },
}
